var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('main',{staticClass:"container"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('Slot')))]),(
        (_vm.auctionList && _vm.auctionList.length > 0) ||
        (_vm.decList && _vm.decList.length > 0) ||
        (_vm.cenList && _vm.cenList.length > 0)
      )?[(_vm.auctionList && _vm.auctionList.length > 0)?_c('h3',[_vm._v("Auction")]):_vm._e(),(_vm.auctionList && _vm.auctionList.length > 0)?_c('ul',{staticClass:"dapp-list"},_vm._l((_vm.auctionList),function(project){return _c('li',{key:project.ID,staticClass:"dapp-info clearfix",on:{"click":function($event){return _vm.goDetail(project)}}},[(project.img)?_c('img',{attrs:{"src":'https://oss-us-cdn.maiziqianbao.net/polka_project/' +
              project.img}}):_c('img',{attrs:{"src":require("@/assets/img/icon/default.png")}}),_c('div',{staticClass:"dapp-text"},[_c('h4',{staticClass:"dapp-title"},[_vm._v(_vm._s(project.title))]),_c('p',{staticClass:"contact"},_vm._l((_vm.getProjectContact(project)),function(item,i){return _c('img',{key:i,attrs:{"src":require('@/assets/img/tag/' + item + '.png')},on:{"click":function($event){$event.stopPropagation();return _vm.openLink(project[item], item)}}})}),0),_c('p',{staticClass:"dapp-slogan"},[_vm._v(_vm._s(project.introduction))]),_c('div',{staticClass:"dapp-type"},[_c('label',[_vm._v(_vm._s(_vm.cateTitleByID(project)))]),(project.tags.length > 0)?_vm._l((project.tags),function(item,index){return _c('label',{key:index},[_vm._v(_vm._s(item)+" ")])}):_vm._e()],2)])])}),0):_vm._e(),(_vm.decList && _vm.decList.length > 0)?_c('h3',[_vm._v(" Crowdloan (Decentralized) ")]):_vm._e(),(_vm.decList && _vm.decList.length > 0)?_c('ul',{staticClass:"dapp-list"},_vm._l((_vm.decList),function(project){return _c('li',{key:project.ID,staticClass:"dapp-info clearfix",on:{"click":function($event){return _vm.goDetail(project)}}},[(project.img)?_c('img',{attrs:{"src":'https://oss-us-cdn.maiziqianbao.net/polka_project/' +
              project.img}}):_c('img',{attrs:{"src":require("@/assets/img/icon/default.png")}}),_c('div',{staticClass:"dapp-text"},[_c('h4',{staticClass:"dapp-title"},[_vm._v(_vm._s(project.title))]),_c('p',{staticClass:"contact"},_vm._l((_vm.getProjectContact(project)),function(item,i){return _c('img',{key:i,attrs:{"src":require('@/assets/img/tag/' + item + '.png')},on:{"click":function($event){$event.stopPropagation();return _vm.openLink(project[item], item)}}})}),0),_c('p',{staticClass:"dapp-slogan"},[_vm._v(_vm._s(project.introduction))]),_c('div',{staticClass:"dapp-type"},[_c('label',[_vm._v(_vm._s(_vm.cateTitleByID(project)))]),(project.tags.length > 0)?_vm._l((project.tags),function(item,index){return _c('label',{key:index},[_vm._v(_vm._s(item)+" ")])}):_vm._e()],2)])])}),0):_vm._e(),(_vm.cenList && _vm.cenList.length > 0)?_c('h3',[_vm._v(" Crowdloan (Centralized) ")]):_vm._e(),(_vm.cenList && _vm.cenList.length > 0)?_c('ul',{staticClass:"dapp-list"},_vm._l((_vm.cenList),function(project){return _c('li',{key:project.ID,staticClass:"dapp-info clearfix",on:{"click":function($event){return _vm.goDetail(project)}}},[(project.img)?_c('img',{attrs:{"src":'https://oss-us-cdn.maiziqianbao.net/polka_project/' +
              project.img}}):_c('img',{attrs:{"src":require("@/assets/img/icon/default.png")}}),_c('div',{staticClass:"dapp-text"},[_c('h4',{staticClass:"dapp-title"},[_vm._v(_vm._s(project.title))]),_c('p',{staticClass:"contact"},_vm._l((_vm.getProjectContact(project)),function(item,i){return _c('img',{key:i,attrs:{"src":require('@/assets/img/tag/' + item + '.png')},on:{"click":function($event){$event.stopPropagation();return _vm.openLink(project[item], item)}}})}),0),_c('p',{staticClass:"dapp-slogan"},[_vm._v(_vm._s(project.introduction))]),_c('div',{staticClass:"dapp-type"},[_c('label',[_vm._v(_vm._s(_vm.cateTitleByID(project)))]),(project.tags.length > 0)?_vm._l((project.tags),function(item,index){return _c('label',{key:index},[_vm._v(_vm._s(item)+" ")])}):_vm._e()],2)])])}),0):_vm._e()]:_c('p',{staticClass:"null"},[_vm._v(_vm._s(_vm.$t("NoInformation")))])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }